import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import '../generic-page.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { IReverseCharacter } from '../../modules/common/model/graphql-types';
import { faAnglesDown, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { Reverse1999Character } from '../../modules/reverse/common/components/rev-character';
import { RevTagsArray } from '../../modules/reverse/common/components/rev-tags';
import lodash from 'lodash';

interface IReverseCharacterNodes {
  nodes: IReverseCharacter[];
}

interface IReverseCharacterEntry {
  allCharacters: IReverseCharacterNodes;
}

interface IProps {
  data: IReverseCharacterEntry;
}

const REVTierPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [tags, setTags] = useState('tierListTags');
  const [category, setCategory] = useState('tierListCategory');

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '2✦', value: '2' },
          { label: '3✦', value: '3' },
          { label: '4✦', value: '4' },
          { label: '5✦', value: '5' },
          { label: '6✦', value: '6' }
        ]
      },
      {
        key: 'afflatus',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Star',
            tooltip: 'Star',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_star.png"
                width={24}
                alt="Star"
              />
            )
          },
          {
            value: 'Plant',
            tooltip: 'Plant',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_plant.png"
                width={24}
                alt="Plant"
              />
            )
          },
          {
            value: 'Mineral',
            tooltip: 'Mineral',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_mineral.png"
                width={24}
                alt="Mineral"
              />
            )
          },
          {
            value: 'Beast',
            tooltip: 'Beast',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_beast.png"
                width={24}
                alt="Beast"
              />
            )
          },
          {
            value: 'Spirit',
            tooltip: 'Spirit',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_spirit.png"
                width={24}
                alt="Spirit"
              />
            )
          },
          {
            value: 'Intellect',
            tooltip: 'Intellect',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_intellect.png"
                width={24}
                alt="Intellect"
              />
            )
          }
        ]
      },
      {
        key: 'damageType',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Mental',
            label: 'Mental'
          },
          {
            value: 'Reality',
            label: 'Reality'
          }
        ]
      },
      { key: 'tags', type: 'dropdown', values: RevTagsArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (sortOption === 'base') {
      setTags('tierListTags');
      setCategory('tierListCategory');
    }

    if (sortOption === 'euphoria') {
      setTags('tierListTagsEuphoria');
      setCategory('tierListCategoryEuphoria');
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.afflatus && activeFilters.afflatus !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.afflatus.toLowerCase() === activeFilters.afflatus.toLowerCase()
        );
      }
      if (activeFilters.damageType && activeFilters.damageType !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.damageType &&
            emp.damageType.length > 0 &&
            emp.damageType.indexOf(activeFilters.damageType) > -1
        );
      }
      if (activeFilters.tags && activeFilters.tags !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.tags &&
            emp.tags.length > 0 &&
            emp.tags.indexOf(activeFilters.tags) > -1
        );
      }
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: 'base', label: 'Overall' },
    { value: 'euphoria', label: 'Overall (Euphoria)' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page reverse-tier '} game="reverse">
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/reverse/categories/category_tier.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Reverse: 1999 Tier List</h1>
          <h2>
            A tier list for Reverse: 1999 that rates all characters based on
            their Global, CN and Overall performance.
          </h2>
          <p>
            Last updated: <strong>03/04/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Tier List" />
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>About the Tier List</Accordion.Header>
          <Accordion.Body>
            <p>
              Reverse:1999 is a game where{' '}
              <strong>team building and strategy is the main focus</strong>, so
              take the ratings listed here with a grain of salt, as most
              characters can perform well when under the right team and
              circumstances, while conversely, most characters rely on a good
              synergy with their teammates to deliver a good performance.
            </p>
            <p>
              To build this Tier List, we tested all characters both on their
              lower levels and high level, as well as both in optimal and
              non-optimal scenarios. We took into consideration{' '}
              <strong>
                their versatility, performance compared to other characters of
                the same role/niche, and if they grow or fall off as the game
                progresses
              </strong>
              .
            </p>
            <h6>Tier List Options</h6>
            <ul className="no-colors">
              <li>
                <strong>Overall</strong>: How the character performs ignoring
                the patch specific content - the rating showcases the overall
                character strength,
              </li>
              <li>
                <strong>Overall (Euphoria)</strong>: How the character performs
                ignoring the patch specific content including the impact of
                Euphoria upgrades on the meta - the rating showcases the overall
                character strength.
              </li>
            </ul>
            <h6>Tier List Criteria</h6>
            <ul>
              <li>
                <strong className="dps">Damage Dealer</strong>: Characters whose
                main function is to deal damage.
              </li>
              <li>
                <strong className="sub-dps">Sub Carry</strong>: Characters who
                can both deal damage and help the team in some way.
              </li>
              <li>
                <strong className="support">Support</strong>: Arcanists who you
                bring to the team for their utility or support aspects, be it
                buffs, debuffs, control, and so on.
              </li>
              <li>
                <strong className="sustain">Survival</strong>: Healers, Tanks,
                and the ones with tools who help keep the team alive.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Ratings explained</Accordion.Header>
          <Accordion.Body>
            <ul className="no-margin">
              <li>
                <strong>S+</strong> - characters that are the best at their role
                for this content.
              </li>
              <li>
                <strong>S</strong> - powerful characters that are slightly
                inferior to the S+ ones, but there are some things holding them
                back.
              </li>
              <li>
                <strong>A+</strong> - strong character that perform well that
                still have place in the meta, but require a bit more work to
                shine.
              </li>
              <li>
                <strong>A</strong> - decent characters that are commonly used to
                replace the meta ones when you don't have them or they have some
                niche uses.
              </li>
              <li>
                <strong>B</strong> - average characters that have big flaws and
                only work as some niche replacements when you don't have
                anything else.
              </li>
              <li>
                <strong>C</strong> - characters with very niche kits or that
                struggle due to low stats.
              </li>
              <li>
                <strong>D</strong> - characters with niche kits that are
                difficult to justify, as well as having low stats.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Changelog</Accordion.Header>
          <Accordion.Body>
            <h6>03.04.2025</h6>
            <p>New additions (2.6):</p>
            <ul>
              <li>
                <strong>Aleph</strong> [A+/A+] A key release for the Inspiration
                Team, Aleph single-handedly allows "Impromptu Incantation" to
                catch up to other meta teams in terms of damage through
                [Interpretation] and recasting other teammates' Incantations.
                Then, why are they in A+? Because they are also the most
                replaceable unit in that team. Aleph further exacerbates the
                existing issues of the team such as being AP-negative despite
                their [Continuous Action I] and them burning less Eureka than
                Flutterpage.
              </li>
            </ul>
            <h6>12.03.2025</h6>
            <p>New additions (2.6):</p>
            <ul>
              <li>
                <strong>Recoleta</strong> [S/S+] Probably one of the strongest
                recent releases. Recoleta is our first look at a Year 2
                Ultimate-based Carry. Following in the footsteps of Melania (by
                taking her as a Support) and Spathodea, Recoleta both smashes
                enemies with massive Ultimates, generates Moxie for herself and
                allies and can even force allies to cast their Ultimates with
                "Ficciones" (which is the first instance of actual Ultimate
                cheating we have). She deals and will deal significantly more
                damage as we get Ultimate-oriented Supports.
              </li>
            </ul>
            <p>Overall Changes:</p>
            <ul>
              <li>
                <strong>Regulus</strong> [A/Sub Carry -&gt; B/Sub Carry]: Still
                does decent damage, not the worst 6-star character in the game
                but could definitely use some love due to having literally 0
                gimmicks and just not having the numbers to keep up.
              </li>
            </ul>
            <p>Overall (Euphoria) Changes:</p>
            <ul>
              <li>
                <strong>Melania</strong> [A/Carry -&gt; S/Sub Carry]: Bluepoch
                said "We need our queen back in S tier" and then immediately
                delivered. With this Euphoria, Melania takes on the role of a
                supportive Carry by granting teamwide [Fixed Plan], massively
                increasing Ultimate Might and allowing allies to store excess
                Moxie. When put next to Recoleta, Melania makes her S+ tier.
              </li>
              <li>
                <strong>Medicine Pocket</strong> [A+/Survival -&gt; S/Survival]:
                With HP Loss based healing, allowing your team to sacrifice HP,
                providing a bit of shielding and their debuff now also
                inflicting a Crit RES/DEF debuff Medicine Pocket becomes the
                premier raw healer that's not gimmick locked.
              </li>
              <li>
                <strong>J</strong> [S -&gt; A+]: Simply not on the level of
                Liang Yue, Tuesday and Melania as a unit. Can provide lots of
                Extra Actions and massive shields in combination with Ms
                NewBabel(E) but doesn't do much for the team besides that.
              </li>
              <li>
                <strong>Tooth Fairy</strong> [S -&gt; A+]: Unfortunately, with
                all the other Survival units getting Euphorias that give them
                outrageous sustain and the top Survival units having been at the
                top of meta for a while now, Tooth Fairy is slowly getting
                pushed out.
              </li>
              <li>
                {' '}
                <strong>Regulus</strong>: See Overall Changes
              </li>
            </ul>
            <h6>17.02.2025</h6>
            <p>New additions (2.5):</p>
            <ul>
              <li>
                New Addition: <strong>Noire</strong> [A+/A+] Lots of potential,
                unfortunately struggles with finding team placements now. Due to
                her high AP requirement, Liang Yue doesn't really slot into her
                teams that well and while An-An Lee (Euphoria) does function,
                she didn't get enough from her Euphoria to really hold up in
                teams. Besides that, Noire doesn't really have proper teammates,
                and will unfortunately hang on the lower end until that problem
                is alleviated.
              </li>
            </ul>
            <h6>27.01.2025</h6>
            <p>New additions (2.5):</p>
            <ul>
              <li>
                New Addition: <strong>Liang Yue</strong> [S/S] While a very
                powerful unit with a lot of Follow-Up Attacks, top tier damage
                output and very good defensive buffing, Liang Yue's rotation
                without P1 can be frustrating to optimize due to being unable to
                upkeep Qiangliang permanently. Thus, without that important P1,
                she isn't as good as other Limited characters on release.
              </li>
              <li>
                New Addition: <strong>Loggerhead </strong> [A/A] A fairly solid
                defensive Support with quite a surprising amount of healing who
                can randomly inflict [Poison], [Burn] and [Rigidity]/[Petrify].
                This combination allows her to be used in the defensive core of
                teams such as with J (if no NewBabel) or Tuesday (DOT Team).
              </li>
            </ul>
            <p>General Changes:</p>
            <ul>
              <li>
                <strong>Fatutu</strong> Tag-Change: Tank &gt; Defense
              </li>
              <li>
                <strong>Willow</strong> New-Tag: Tuesday
              </li>
            </ul>
            <p>Overall (No Euphoria) Changes:</p>
            <ul>
              <li>
                <strong>Druvis III</strong> [A+ to A]: With the exceptional
                additions to Plant teams lately, it's harder and harder to find
                a place for Druvis in those teams due to her below-standard
                damage output and focus on Petrify, which is not a
                well-supported niche.
              </li>
              <li>
                <strong>Flutterpage</strong> [S to S+]: A maybe unsurprising
                promotion to the S+. With how focused character releases have
                been on Follow-Up Attacks, and with how no-brainer she is to add
                to any team that has a modicum of consistent FUAs, Flutterpage
                has been stealing team spots left and right. Do note that if
                your team is not castign 4+ FUAs a round she will be harder to
                use.
              </li>
              <li>
                <strong>Isolde</strong> [S to A+]: Isolde has been a very
                powerful unit since her release in "É Lucevan Le Stelle",
                however, this was primarily to there just not being characters
                of that level that could do so much while also providing so much
                damage output. At this time, due to Burn being underrepresented
                in the meta, Isolde may struggle a bit to find a spot.
              </li>
              <li>
                <strong>Tennant</strong> [A to B]: Without her Euphoria Tennant
                kind of just lags behind due to her low shield values and
                struggle to cycle her Ultimate.
              </li>
              <li>
                <strong>Willow</strong> [S to S+]: With Tuesday, Willow is
                perhaps the only character who can challenge Windsong at the
                golden horse she is sitting on. This, however, specifically
                requires Tuesday, as she cannot remotely do enough damage to
                even challenge Spathodea or Barcarola on her own. Note also that
                this is an incredibly difficult team to run, but when done
                properly, it is broken.
              </li>
            </ul>
            <p>Overall (Euphoria) Changes:</p>
            <ul>
              <li>
                <strong>Flutterpage</strong> [S to S+]: See Overall Changes.
              </li>
              <li>
                <strong>Jiu Niangzi</strong> [A+ to S]: With the introduction of
                Pickles' euphoria, JNZ finally has a bit of stability in her
                damage. She can't make perfect use of his buffs (as they're
                layered ones which JNZ struggles with using) but it also allows
                her to slot Flutterpage into her teams, giving her a strong
                offensive base.
              </li>
              <li>
                <strong>Barcarola</strong> [S to A+]: While Inspiration is
                significantly stronger with Euphorias unlocked (Voyager and
                Matilda), the team struggles to find a solid base. Having to use
                a 5-star that can't be easily given Portraits and having no
                synergistic Survival unit is causing the archetype to struggle
                compared to more longstanding ones.
              </li>
              <li>
                <strong>Isolde</strong> [S to A+]: See Overall Changes
              </li>
              <li>
                <strong>Pickles</strong> [A to A+]: The best boy finally gets a
                bit of attention. With his brand spanking new Euphoria, he is
                great at providing support for FUA teams. He gets his own FUA
                with super consistent Dispel, can offensively buff the ally
                directly in front of him and also increases that unit's FUA DMG
                Dealt. All of these effects are enhanced if the targeted ally is
                Mineral. This does lock Pickles to a specific archetype (Jiu
                Niangzi, Semmelweis) but helps him in the long run.
              </li>
              <li>
                <strong>An-An Lee</strong> [A to A+]: She also gets a Euphoria,
                and with it, becomes significantly stronger than she was before.
                She can increase the ranks of allied cards, grant defensive
                buffs, inflict Poison/Burn/Rigidity randomly and she deals
                increased damage against debuffed enemies. While her usual team
                is really Euphoria heavy, and she doesn't look as showy as
                Pickles' changes, there is still hidden strength in this.
              </li>
              <li>
                <strong>Ezra Theodore</strong> [A+ to A]: Ezra really struggles
                to find a place. With how long his (arguably quite strong) buffs
                and debuffs take to set up versus how fast they're consumed, on
                top of Ezra's low DMG and low shield duration, it's a rough time
                for buddy.
              </li>
              <li>
                <strong>Lorelei</strong> [A+ to A]: While still among the top of
                the 5-star category with her damage, crit and defensive buffs,
                with Euphoria a lot of accessible 6-star units have started to
                match her level.
              </li>
              <li>
                <strong>Brimley</strong> [A to B]
              </li>
              <li>
                <strong>Sweetheart</strong> [B to C]
              </li>
              <li>
                <strong>Leilani</strong> [B to C]
              </li>
              <li>
                <strong>Pavia</strong> [C to D]
              </li>
            </ul>
            <h6>06.01.2025</h6>
            <p>New additions (2.4):</p>
            <ul>
              <li>
                New Addition: <strong>Fatutu</strong> [S+/S+] Extremely powerful
                healer capable of passively outputting massive amounts of
                healing on top of spreading damage taken by teammates across the
                entire team. On top of that she is capable of greatly increasing
                the damage done of Follow-Ups which is especially potent for
                units like 37, Anjo Nala and Jiu Niangzi, though her sheer
                defensive power alone can offer her that S+ spot.
              </li>
            </ul>
            <p>Role Changes:</p>
            <ul>
              <li>
                <strong>37</strong> [Sub Carry to Damage Dealer]: Due to Fatutu
                existing, 37 is actually capable of outputting very competitive
                damage!
              </li>
              <li>
                <strong>Kakania</strong> [Survival to Support]: Due to the use
                of her kit and the point of her usage in high end content
                (defensive supporting and ATK buffing) AND constant questions
                regarding her place amongst characters like Vila and Tooth Fairy
                we felt it best for her to be switched to this role instead
              </li>
            </ul>
            <p>Overall Changes:</p>
            <ul>
              <li>
                <strong>37</strong> [A+ to S]: Now that Flutterpage and Fatutu
                form actual meaningful Supports specifically for enhancing the
                damage of FUAs, which is 37's entire shtick.
              </li>
              <li>
                <strong>A Knight</strong> [C to B]: We were a bit rough against
                AK due to his mediocre performance, he works fine as is (but
                could definitely use a Euphoria).
              </li>
            </ul>
            <p>Euphoria Changes:</p>
            <ul>
              <li>
                <strong>37</strong> [A+ to S]: Now that Flutterpage and Fatutu
                form actual meaningful Supports specifically for enhancing the
                damage of FUAs, which is 37's entire shtick.
              </li>
              <li>
                <strong>A Knight</strong> [C to B]: See Overall Changes
              </li>
              <li>
                <strong>Voyager</strong> [A to A+]: Was supposed to happen
                previous tierlist update.
              </li>
            </ul>
            <h6>18.12.2024</h6>
            <p>New additions (2.4):</p>
            <ul>
              <li>
                New Addition: <strong>Barcarola</strong> [A+/S] Not really a DPS
                in the traditional sense, we decided to put Barcarola in the
                Damage Dealer position due to her ability to drastically enhance
                the effectiveness of "Impromptu Incantation". Without her, it
                wouldn't be doing the damage it does, therefore we consider her
                its "DPS" for the time being.
              </li>
            </ul>
            <p>General (w/o Euphoria) Changes:</p>
            <ul>
              <li>
                <strong>An-An Lee</strong> [A+ to A]: Generalist Supports
                generally struggle nowadays if they don't have some ridiculous
                numbers or gimmicks in their kit, something that AAL sadly
                doesn't have.
              </li>
              <li>
                <strong>Argus</strong> [Sub Carry to Support]: She might have
                the kit of a Sub Carry, however, she is exclusively used for
                [Pinpointed] since her personal output is abysmal.
              </li>
              <li>
                <strong>Avgust</strong> [A to B]: Good as a general stat
                inflater but doesn't have the numbers nor the gimmicks to keep
                up with newer Supports.
              </li>
              <li>
                <strong>Baby Blue</strong> [B to C]; Just struggles in general
                due to her AP-negative kit, debuffs being split across
                Incantations and [Nightmare] still being a garbage form of CC.
              </li>
              <li>
                <strong>Balloon Party</strong> [A to B]: Lost HP based healing
                isn't really that good anymore outside of general content and BP
                has nothing in her kit to make up for it.
              </li>
              <li>
                <strong>Bkornblume</strong> [A+ to A]: Still decently strong,
                but her damage output outside of her Ultimate and debuff values
                aren't that interesting anymore.
              </li>
              <li>
                <strong>Centurion</strong> [A to B]: Oh how far she's fallen. A
                comfy Limbo DPS but certainly not what she used to be, between
                struggling to find her playstyle to her Insights generally being
                weak. Maybe a Euphoria would help.
              </li>
              <li>
                <strong>Charlie</strong> [A to B]: Joining at Centurion's side.
                Charlie just doesn't have enough bullshit in her kit to make it
                work. Her numbers are fine, she has access to dispel, but
                there's just nothing to make her pack an extra punch.
              </li>
              <li>
                <strong>Cristallo</strong> [C to D].
              </li>
              <li>
                <strong>Darley Clatter</strong> [B to C] : No gimmick outside of
                Moxie teams.
              </li>
              <li>
                <strong>Dikke</strong> [C to B]: We were a little harsh with
                her. Decent healing output with some damage on the side, and
                [Immunity] is always nice to have.
              </li>
              <li>
                <strong>Eagle</strong> [C to D]: Sad to see her go, but beyond
                the fun Raid buff and being an early game stomper, Eagle just
                provides nothing.
              </li>
              <li>
                <strong>Eternity</strong> [B to C]: With a rearrangement in the
                DPS category Eternity (as one of its worst 6-stars)
                unfortunately just gets pushed down. Beyond her tankiness and
                ability to ignore annoying DoTs, she provides very little.
              </li>
              <li>
                <strong>Getian</strong> [S to A+]: While strong, the low
                modifier on [Shape of Bones] and lack of easy way to generate
                Moxie for his Array makes him struggle a bit beyond the first 3
                rounds of combat.
              </li>
              <li>
                <strong>Isolde</strong> [Support to Sub Carry]: Generally, she
                isn't used for her Debuff Incantation anymore. Her primary use
                nowadays is her extremely high and consistent Mass DMG output
                and to provide [Burn] for units like Lopera.
              </li>
              <li>
                <strong>Jessica</strong> [A+ to A]: With a shift in the DPS
                category, Jessica sadly suffers. With Tuesday playing much more
                nicely with Willow, Jessica has been left behind both as a
                debuff and a Poison Carry. Her damage is still outstanding for
                general content, but she doesn't meet the standards of endgame.
              </li>
              <li>
                <strong>Kaalaa Baunaa</strong> [A+ to A]: Also suffers from the
                shift in her category, KB just hasn't gotten any attention from
                Bluepoch ever since her release in the 1.3 version. Her
                Incantations aren't too good, despite being the focus of her
                kit, and she doesn't have anything to help herself with
                generating her Ultimate in order to buff said Incantations.
              </li>
              <li>
                <strong>Kanjira</strong> [B to C]: Just too strict in what she
                wants to do with no way besides being AP-negative to execute
                said playstyle.
              </li>
              <li>
                <strong>Lilya</strong> [A to B]: Suffers from the shift in her
                category. Her Euphoria is broken, however, that just continues
                to highlight the importance of catering to a niche and having a
                kit built around it, which base Lilya can't do.
              </li>
              <li>
                <strong>Lopera</strong> [S+ to S]: Matches Mercuria in offensive
                Support, who is matched by Argus on 1-target fights in turn.
                Lopera's main argument is her ease of use compared to her
                top-Support peers.
              </li>
              <li>
                <strong>Melania</strong> [A+ to A]: Suffers from the shift in
                her category. Still a very solid Raid unit with some use in the
                upcoming Reveries (2.3) mode, she just... doesn't have enough
                gimmicks or numbers to keep up with units like Lilya (E1) and
                Willow(+Tuesday)
              </li>
              <li>
                <strong>Mr. Duncan</strong> [A to C]: A very heavy hit, but he
                unfortunately just doesn't seem to be able to keep up with
                6-star DPS units.
              </li>
              <li>
                <strong>Necrologist</strong> [A+ to A]: Still very solid in
                Raids, but struggles to keep up with Supports that can provide
                more beyond +50% DMG Bonus and [Prayer].
              </li>
              <li>
                <strong>Pavia</strong> [C to D].
              </li>
              <li>
                <strong>Satsuki</strong> [B to D]: See Mr. Duncan.
              </li>
              <li>
                <strong>Spathodea</strong> [S to A+]: Suffers from the DPS
                shift. Spathodea, while great, is much more of an
                Ultimate-oriented Crit unit than she is a [Burn] unit.
                Unfortunately, [Burn] hasn't been given much attention and her
                playstyle conflicts with other, better, units.
              </li>
              <li>
                <strong>Sweetheart</strong> [B to C]: See Mr. Duncan.
              </li>
              <li>
                <strong>Twins Sleep</strong> [B to C]: Struggles for the same
                reason as Baby Blue. While their CDEF debuff is much better when
                inflicted under [Arcane Stamp], their low stats and lack of
                Insight 3 hold them back.
              </li>
              <li>
                <strong>Willow</strong> [A+ to S]: Through the new blurb we are
                considering Tuesday as part of the rating for Willow. Honestly,
                when paired like this, Willow should be S+ next to Windsong and
                Lucy. However, Willow+Tuesday is the hardest duo to play in the
                game due to Ultimate uptime and AP management, therefore we are
                keeping her in S.
              </li>
            </ul>
            <p>Euphoria Changes</p>
            <ul>
              <li>
                <strong>Voyager</strong> [A to A+]: Swapped to Sub Carry
                category. The primary addition to the [Inspiration] comp with
                Barcarola, Voyager is key to making "Impromptu Incantation" deal
                consistent damage by allowing it to focus-target one opponent
                with [Orbital Direction] instead of it targeting enemies
                randomly.
              </li>
              <li>
                <strong>Jessica</strong> [A+ to A]: Unfortunately, Jessica's
                first Euphoria is a miss. This is due to [Moss on Stone]
                requiring 10 stacks to trigger, and it only triggering on an
                attack (meaning AP spent, and limited to targets hit). In
                addition, the [Poison] cap on her Incantations, while raised,
                was increased only from 3 to 4, leading to an insignificant
                increase.
              </li>
              <li>
                <strong>Matilda</strong> [D to A]: Swapped to Sub Carry
                category. In what is perhaps the biggest twist of fate, Matilda
                jumps from her almost ironic placing as the worst 5-star in the
                game to a genuinely decent spot as a Sub Carry for the
                [Inspiration] team by increasing the Crit Rate/DMG of "Impromptu
                Incantation" considerably.
              </li>
              <li>
                <strong>Tennant</strong> [Unchanged]: While being an overall
                buff, Tennant's Euphoria is unfortunately too insignificant to
                make a difference. It boosts her overall damage output
                marginally considering the easier access to [Diamond Bullet],
                but with her shields still lasting 1 turn while auto-casting it
                every 4 turns, it's just not that impressive.
              </li>
              <li>
                <strong>A Knight</strong> [A to C]: A heavy hit, but A Knight's
                output just simply isn't that good anymore, and he's almost
                exclusively used to farm Sharpo/Dust stages (though Lucy has him
                beat there as well).
              </li>
              <li>
                <strong>Argus</strong>: See General Changes.
              </li>
              <li>
                <strong>Avgust</strong>: See General Changes.
              </li>
              <li>
                <strong>Barcarola</strong> [A+ to S]: With the addition of
                especially Voyager, Barcarola's value as an "Impromptu
                Incantation" damage booster rises considerably. As a pair, the
                two can even slot into other general comps to provide substitute
                damage.
              </li>
              <li>
                <strong>Bkornblume</strong>: See General Changes.
              </li>
              <li>
                <strong>Blonney</strong> [A to C]: Kind of in a similar struggle
                to Kaalaa Baunaa being an Ultimate reliant Incantation-oriented
                unit with both no way to upgrade her own cards nor generate her
                own Moxie.
              </li>
              <li>
                <strong>Getian</strong>: See General Changes.
              </li>
              <li>
                <strong>Isolde</strong>: See General Changes.
              </li>
              <li>
                <strong>Jiu Niangzi</strong> [S to A+]: The shock of this patch.
                JNZ prides herself on her Follow-Up consistency, however,
                recently other units have been challenging her on that front.
                Unfortunately, JNZ lacks burst, and her damage output
                randomizing based on [Liquor] consumption makes her struggle on
                the consistency front as well.
              </li>
              <li>
                <strong>Kaalaa Baunaa</strong>: See General Changes.
              </li>
              <li>
                <strong>Kanjira</strong>: See General Changes.
              </li>
              <li>
                <strong>Lopera</strong>: See General Changes.
              </li>
              <li>
                <strong>Melania</strong>: See General Changes.
              </li>
              <li>
                <strong>Mr. Duncan</strong>: See General Changes.
              </li>
              <li>
                <strong>Necrologist</strong>: See General Changes.
              </li>
              <li>
                <strong>Satsuki</strong>: See General Changes.
              </li>
              <li>
                <strong>Spathodea</strong>: See General Changes.
              </li>
              <li>
                <strong>Twins Sleep</strong>: See General Changes.
              </li>
              <li>
                <strong>Willow</strong>: See General Changes.
              </li>
            </ul>
            <h6>02.12.2024</h6>
            <p>New additions (2.3):</p>
            <ul>
              <li>
                New Addition: <strong>Flutterpage</strong> [S/S] A great Sub
                Carry specifically designed around FUAs. If slotted into the
                right team (i.e. teams that can cast 4+ FUAs per round), can
                boost FUA DMG up to around 250% at P0 (~700% at P5!) while
                granting various other powerful buffs such as Crit, AP and
                additional Reality DMG.
              </li>
              <li>
                New Addition: <strong>Brimley</strong> [A/A] A decent Sub Carry
                with some very interesting dynamics in allowing any ally to use
                Extra Actions by forcing them to cast skills. Does well in with
                characters that use conditional FUAs such as Lilya or Anjo Nala!
              </li>
              <li>
                Promotion: <strong>Ms. NewBabel</strong> [A+ to S, Euphoria]
                Works better than J on an individual level, really powerful
                synergy with FUA teams now that she can fuel them more easily
                and also apply bigger shields.
              </li>
              <li>
                Demotion: <strong>Satsuki</strong> [A to B, both] Is hard to
                justify using over any other Carry even in teams specifically
                oriented around CC, units like Anjo Nala and An-An Lee just
                perform better.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="tier-list-header">
        <p className="title">
          Tier List{' '}
          <span>
            ({sortOption === 'base' && 'Overall'}
            {sortOption === 'euphoria' && 'Overall - Euphoria'})
          </span>
        </p>
        <p className="sub-title">
          You're currently viewing the{' '}
          <strong>
            {sortOption === 'base' && 'Overall'}
            {sortOption === 'euphoria' && 'Overall - Euphoria'}
          </strong>{' '}
          tier list. It shows{' '}
          {sortOption === 'base' &&
            'how the character performs ignoring the patch specific content - the rating showcases the overall character strength.'}{' '}
          {sortOption === 'euphoria' &&
            'how the character performs ignoring the patch specific content - the rating showcases the overall character strength including the Euphoria upgrades.'}{' '}
          Use the switcher below to view a different tier list.
        </p>
      </div>
      <div className="tier-list-switcher">
        <div
          className={`option overall ${sortOption === 'base' && 'selected'}`}
          onClick={() => setSortOption('base')}
        >
          <p>Overall</p>
        </div>
        <div
          className={`option standard ${
            sortOption === 'euphoria' && 'selected'
          }`}
          onClick={() => setSortOption('euphoria')}
        >
          <p>Overall (Euphoria)</p>
        </div>
      </div>
      <div className="employees-filter-bar">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="custom-tier-list-reverse">
        <div className="custom-tier-header">
          <div className="tier-rating">
            <span>&nbsp;</span>
          </div>
          <div className="custom-tier-container">
            <div className="custom-header dps">Damage dealer</div>
            <div className="custom-header sub-dps">Sub Carry</div>
            <div className="custom-header support">Support</div>
            <div className="custom-header survival">Survival</div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header meta">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Best In Role{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-s first">
          <div className="tier-rating s-plus">
            <span>S+</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header top">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Top Picks{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-s">
          <div className="tier-rating s">
            <span>S</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
        <div className="custom-tier tier-a-plus">
          <div className="tier-rating a-plus">
            <span>A+</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header alt">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Common
            Replacements <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-a">
          <div className="tier-rating a">
            <span>A</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-b">
          <div className="tier-rating b">
            <span>B</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
        <div className={`meta-line`}>
          <h5 className="meta-header niche">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche Picks{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-c">
          <div className="tier-rating c">
            <span>C</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-e">
          <div className="tier-rating d">
            <span>D</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp[category] === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp[category] === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp[category] === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp[category] === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp[tags]}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default REVTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Reverse: 1999 | Prydwen Institute"
    description="A tier list for Reverse: 1999 that rates all characters based on their Global, CN and Overall performance."
    game="reverse"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulReverseCharacter(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        tierListCategory
        tierListTags
        tierListCategoryEuphoria
        tierListTagsEuphoria
        rarity
        afflatus
        damageType
        tags
        ratingsNew {
          base
          euphoria
        }
        tierComment {
          tierComment
        }
        releasePatch
        availableInGlobal
        isReviewPending
        isNew
      }
    }
  }
`;
